//IDMS Authentication Details
export const idms_auth = "https://idmsac.apple.com/IDMSWebAuth/appleauth/auth/oauth2/v2/authorize?client_id=";
export const client_id = 'fu9hmjplqxqcatwafizr5guyy2e5ib';
export const redirect_uri = "https://absence-mgmt-teamview.awusw2.sbz.apple.com";
export const scope = "openid,profile,email,groups,dsid,roles";

export const APPID = 101,
            PAGEID = 101;

export const TFlist = {
    'Week': ['PW', 'CW', 'NW'],
    'Month': ['PM', 'CM', 'NM'],
    'Quarter': ['PQ', 'CQ', 'NQ'],
    'Year': ['PY', 'CY']
}

export const orgKeys = ["Region", "Country", "Market Team", "Market", "Store"];
export const orgKeysRpo = ["RPO Region", "RPO Team", "RPO Pod", "RPO Store"];

export const absenceTypeOptions = {
    'All': ['Excused','Unexcused'],
    'Unexcused only': ['Unexcused only'],
    'Excused only': ['Excused only'],
    'Leave only': ['Leave only'],
    'Excused via benefit time':['Excused via benefit time'],
    'Excused via manual adjustment':['Excused via manual adjustment']
}