import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Headers from './header/Header';
import SummaryCointainer from '../component/summary/SummaryContainer';
import Calender from './calender/Calender';

const AbsenceCointainer = () => {

    return (
        <div id="container">
            <SummaryCointainer/>
            <Calender/>
        </div>


    )
}

export default AbsenceCointainer;