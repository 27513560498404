import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import loadmask from "../component/loadmask/loadmask";
import * as constant from '../constants/Constant';

// const apiClient = axios.create({
//     headers: {
//         'Content-Type': 'application/json; charset=UTF-8',
//         "Accept": "application/json",
//         "Cache": "no-cache",
//         "X-Requested-With": "XMLHttpRequest",
//         "CSRF": window.csrf_token_val
//       }
// });

const initialState = {
    isSigninSuccess: false
};

export const signin = createAsyncThunk(
    "data/signin", // a string identifier for the action type
    async (payload) => {
      loadmask.show();
      try {
        let headers = {
            'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "application/json",
            "Cache": "no-cache",
            "X-Requested-With": "XMLHttpRequest",
            "CSRF": window.csrf_token_val,
            "Authorization": payload.authorizationCode ? payload.authorizationCode : ""
        };
        
        // Perform asynchronous operation here, such as fetching data from an API
        const response = await axios({
        url: '/gsf/amteamview/signin',
        headers: headers,
        method: 'POST'})

        loadmask.hide();

        return response.data;
      } catch (error) {
        loadmask.hide();
        throw error;
      }
    }
);

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {},
  extraReducers: (builder) => { 
        builder.addCase(signin.pending, (state) => {
            //state.loading = true;
        });

        builder.addCase(signin.fulfilled, (state, action) => {
            //console.log(action.payload)
            state.isSigninSuccess = action.payload && action.payload.status === 'success' ? true : false
            //state.data = action.payload.result;
            //state.loading = false;
        });

        builder.addCase(signin.rejected, (state, action) => {
            const { error } = action;
            state.error = error;
            //state.loading = false;
        });
  }
});

export default authenticationSlice.reducer;