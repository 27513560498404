import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import { Provider } from 'react-redux';
import { Reporter } from '@buzz/columbo-reporter-web';

const root = ReactDOM.createRoot(document.getElementById('root'));

const reporter = new Reporter();
//let columboId_team = 'ec941433017623b0775a8321e24e2645ddfad2959531de63ba354bbd98c6eb28';
//let columboEndPoint_team = 'https://columbo-event-uat-i.corp.apple.com/api/v2';

console.log("----host-----", window.location.host)
let columboId_team = '64b2696c76caabc0b0677fb69bd8fb9c14cda92ad956b565580481d997734cfc';
let columboEndPoint_team = 'https://columbo-event.apple.com/api/v2';

reporter.init({
  appConfig: {
    version: '0.0.1',
    columboId: columboId_team
  },
  endpoint: columboEndPoint_team // point to UAT if needed
});

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App reporter={reporter}/>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
