import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BricksTooltip } from '@nexus/bricks-react';
import * as Util from '../../util/Util';
import './Calender.scss';

const Calender = () => {
    const scheduleWeekMapping = {
        'SAT': ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        'MON': ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        'SUN': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    },
    dayCapitalMapping = {
        'Saturday': 'SATURDAY', 'Sunday': 'SUNDAY', 'Monday': 'MONDAY', 'Tuesday': 'TUESDAY', 'Wednesday': 'WEDNESDAY', 'Thursday': 'THURSDAY', 'Friday': 'FRIDAY'
    },
    eventShortNameMapping = {
        'Failure to Notify': 'FTN',
        'Callout': 'CO',
        'No Punch': 'NP',
        'Tardy': 'TD',
        'Late From Lunch': 'LL',
        'Early Out': 'EO'
    },
    legendShapesTextMapping = {
        'Out of Scope': {
            text: 'Out of Scope',
            shapeCls: 'legend-icon-out-of-scope',
            textCls: 'out-of-scope'
        },
        'Excused': {
            text: 'Excused',
            shapeCls: 'legend-icon-excused',
            textCls: 'excused'
        },
        'Unexcused': {
            text: 'Unexcused',
            shapeCls: 'legend-icon-unexcused',
            textCls: 'unexcused'
        }, 
        'No Event': {
            text: 'No Event',
            shapeCls: 'legend-icon-no-event',
            textCls: 'no-event'
        }
    };
    const calenderDetails = [
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-02",
            "Child_Cd": "Q2W1",
            "SCHED_YEAR_WEEK_NR": 202414,
            "Day_Name": "TUESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "09:30",
            "Shift_End_Tm_Aggr": "18:30",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-01",
            "Threshold_Val": 3,
            "Point_Balance_Val": 1,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 8,
            "Clocked_Time_Hrs": 8.07,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-03",
            "Child_Cd": "Q2W1",
            "SCHED_YEAR_WEEK_NR": 202414,
            "Day_Name": "WEDNESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-01",
            "Threshold_Val": 3,
            "Point_Balance_Val": 1,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.07,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-04",
            "Child_Cd": "Q2W1",
            "SCHED_YEAR_WEEK_NR": 202414,
            "Day_Name": "THURSDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-01",
            "Threshold_Val": 3,
            "Point_Balance_Val": 1,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.05,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-05",
            "Child_Cd": "Q2W1",
            "SCHED_YEAR_WEEK_NR": 202414,
            "Day_Name": "FRIDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-01",
            "Threshold_Val": 3,
            "Point_Balance_Val": 1,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.07,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-06",
            "Child_Cd": "Q2W2",
            "SCHED_YEAR_WEEK_NR": 202415,
            "Day_Name": "SATURDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "10:00",
            "Shift_End_Tm_Aggr": "19:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-06",
            "Threshold_Val": 3,
            "Point_Balance_Val": 2,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 8,
            "Clocked_Time_Hrs": 8.13,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-07",
            "Child_Cd": "Q2W2",
            "SCHED_YEAR_WEEK_NR": 202415,
            "Day_Name": "SUNDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "11:30",
            "Shift_End_Tm_Aggr": "18:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-06",
            "Threshold_Val": 3,
            "Point_Balance_Val": 2,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 6,
            "Clocked_Time_Hrs": 6.07,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-10",
            "Child_Cd": "Q2W2",
            "SCHED_YEAR_WEEK_NR": 202415,
            "Day_Name": "WEDNESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "20:00",
            "Event_Name": "Early Out",
            "Approver_Name": "",
            "Excusal_Status_Cd": "Unexcused",
            "Week_Start_Dt": "2024-01-06",
            "Threshold_Val": 3,
            "Point_Balance_Val": 2,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 3.03,
            "Tot_Planned_Hours_Val": 3,
            "Clocked_Time_Hrs": 3.05,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-11",
            "Child_Cd": "Q2W2",
            "SCHED_YEAR_WEEK_NR": 202415,
            "Day_Name": "THURSDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-06",
            "Threshold_Val": 3,
            "Point_Balance_Val": 2,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.05,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-12",
            "Child_Cd": "Q2W2",
            "SCHED_YEAR_WEEK_NR": 202415,
            "Day_Name": "FRIDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-06",
            "Threshold_Val": 3,
            "Point_Balance_Val": 2,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.05,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-13",
            "Child_Cd": "Q2W3",
            "SCHED_YEAR_WEEK_NR": 202416,
            "Day_Name": "SATURDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "13:30",
            "Shift_End_Tm_Aggr": "20:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-13",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 6,
            "Clocked_Time_Hrs": 6.33,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-14",
            "Child_Cd": "Q2W3",
            "SCHED_YEAR_WEEK_NR": 202416,
            "Day_Name": "SUNDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "11:30",
            "Shift_End_Tm_Aggr": "18:00",
            "Event_Name": "Tardy",
            "Approver_Name": "",
            "Excusal_Status_Cd": "Unexcused",
            "Week_Start_Dt": "2024-01-13",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0.55,
            "Tot_Planned_Hours_Val": 6,
            "Clocked_Time_Hrs": 5.47,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-16",
            "Child_Cd": "Q2W3",
            "SCHED_YEAR_WEEK_NR": 202416,
            "Day_Name": "TUESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "12:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-13",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 8,
            "Clocked_Time_Hrs": 8.08,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-17",
            "Child_Cd": "Q2W3",
            "SCHED_YEAR_WEEK_NR": 202416,
            "Day_Name": "WEDNESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "09:30",
            "Shift_End_Tm_Aggr": "18:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-13",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 7.5,
            "Clocked_Time_Hrs": 7.62,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-18",
            "Child_Cd": "Q2W3",
            "SCHED_YEAR_WEEK_NR": 202416,
            "Day_Name": "THURSDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "09:30",
            "Shift_End_Tm_Aggr": "18:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-13",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 7.5,
            "Clocked_Time_Hrs": 7.57,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-20",
            "Child_Cd": "Q2W4",
            "SCHED_YEAR_WEEK_NR": 202417,
            "Day_Name": "SATURDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "11:00",
            "Shift_End_Tm_Aggr": "18:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-20",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 6,
            "Clocked_Time_Hrs": 6.03,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-21",
            "Child_Cd": "Q2W4",
            "SCHED_YEAR_WEEK_NR": 202417,
            "Day_Name": "SUNDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "11:30",
            "Shift_End_Tm_Aggr": "18:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-20",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 6,
            "Clocked_Time_Hrs": 6.08,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-24",
            "Child_Cd": "Q2W4",
            "SCHED_YEAR_WEEK_NR": 202417,
            "Day_Name": "WEDNESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-20",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.08,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-26",
            "Child_Cd": "Q2W4",
            "SCHED_YEAR_WEEK_NR": 202417,
            "Day_Name": "FRIDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-20",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.07,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-27",
            "Child_Cd": "Q2W5",
            "SCHED_YEAR_WEEK_NR": 202418,
            "Day_Name": "SATURDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "13:00",
            "Shift_End_Tm_Aggr": "20:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-27",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 6,
            "Clocked_Time_Hrs": 6.1,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-28",
            "Child_Cd": "Q2W5",
            "SCHED_YEAR_WEEK_NR": 202418,
            "Day_Name": "SUNDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "11:30",
            "Shift_End_Tm_Aggr": "18:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-27",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 6,
            "Clocked_Time_Hrs": 6.07,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-29",
            "Child_Cd": "Q2W5",
            "SCHED_YEAR_WEEK_NR": 202418,
            "Day_Name": "MONDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "14:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-27",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 6,
            "Clocked_Time_Hrs": 6.15,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-01-30",
            "Child_Cd": "Q2W5",
            "SCHED_YEAR_WEEK_NR": 202418,
            "Day_Name": "TUESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-27",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.05,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-02",
            "Child_Cd": "Q2W5",
            "SCHED_YEAR_WEEK_NR": 202418,
            "Day_Name": "FRIDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-01-27",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.03,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-03",
            "Child_Cd": "Q2W6",
            "SCHED_YEAR_WEEK_NR": 202419,
            "Day_Name": "SATURDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "11:00",
            "Shift_End_Tm_Aggr": "20:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-03",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 8,
            "Clocked_Time_Hrs": 8.08,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-06",
            "Child_Cd": "Q2W6",
            "SCHED_YEAR_WEEK_NR": 202419,
            "Day_Name": "TUESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "12:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-03",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 8,
            "Clocked_Time_Hrs": 8.07,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-07",
            "Child_Cd": "Q2W6",
            "SCHED_YEAR_WEEK_NR": 202419,
            "Day_Name": "WEDNESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-03",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.22,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-10",
            "Child_Cd": "Q2W7",
            "SCHED_YEAR_WEEK_NR": 202420,
            "Day_Name": "SATURDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "10:00",
            "Shift_End_Tm_Aggr": "19:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-10",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 8,
            "Clocked_Time_Hrs": 8.08,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-13",
            "Child_Cd": "Q2W7",
            "SCHED_YEAR_WEEK_NR": 202420,
            "Day_Name": "TUESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-10",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.1,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-14",
            "Child_Cd": "Q2W7",
            "SCHED_YEAR_WEEK_NR": 202420,
            "Day_Name": "WEDNESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-10",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.03,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-16",
            "Child_Cd": "Q2W7",
            "SCHED_YEAR_WEEK_NR": 202420,
            "Day_Name": "FRIDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "13:15",
            "Shift_End_Tm_Aggr": "17:15",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-10",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-17",
            "Child_Cd": "Q2W8",
            "SCHED_YEAR_WEEK_NR": 202421,
            "Day_Name": "SATURDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "10:00",
            "Shift_End_Tm_Aggr": "19:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-17",
            "Threshold_Val": 3,
            "Point_Balance_Val": 2,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 8,
            "Clocked_Time_Hrs": 8.25,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-18",
            "Child_Cd": "Q2W8",
            "SCHED_YEAR_WEEK_NR": 202421,
            "Day_Name": "SUNDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "12:30",
            "Shift_End_Tm_Aggr": "19:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-17",
            "Threshold_Val": 3,
            "Point_Balance_Val": 2,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 6,
            "Clocked_Time_Hrs": 6.08,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-20",
            "Child_Cd": "Q2W8",
            "SCHED_YEAR_WEEK_NR": 202421,
            "Day_Name": "TUESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-17",
            "Threshold_Val": 3,
            "Point_Balance_Val": 2,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.02,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-21",
            "Child_Cd": "Q2W8",
            "SCHED_YEAR_WEEK_NR": 202421,
            "Day_Name": "WEDNESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-17",
            "Threshold_Val": 3,
            "Point_Balance_Val": 2,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.02,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-23",
            "Child_Cd": "Q2W8",
            "SCHED_YEAR_WEEK_NR": 202421,
            "Day_Name": "FRIDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-17",
            "Threshold_Val": 3,
            "Point_Balance_Val": 2,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.02,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-24",
            "Child_Cd": "Q2W9",
            "SCHED_YEAR_WEEK_NR": 202422,
            "Day_Name": "SATURDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "10:00",
            "Shift_End_Tm_Aggr": "19:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-24",
            "Threshold_Val": 3,
            "Point_Balance_Val": 2,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 8,
            "Clocked_Time_Hrs": 8.08,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-25",
            "Child_Cd": "Q2W9",
            "SCHED_YEAR_WEEK_NR": 202422,
            "Day_Name": "SUNDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "14:00",
            "Shift_End_Tm_Aggr": "18:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-24",
            "Threshold_Val": 3,
            "Point_Balance_Val": 2,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.05,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-02-27",
            "Child_Cd": "Q2W9",
            "SCHED_YEAR_WEEK_NR": 202422,
            "Day_Name": "TUESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-24",
            "Threshold_Val": 3,
            "Point_Balance_Val": 2,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.02,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-01",
            "Child_Cd": "Q2W9",
            "SCHED_YEAR_WEEK_NR": 202422,
            "Day_Name": "FRIDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-02-24",
            "Threshold_Val": 3,
            "Point_Balance_Val": 2,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.02,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-02",
            "Child_Cd": "Q2W10",
            "SCHED_YEAR_WEEK_NR": 202423,
            "Day_Name": "SATURDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "11:00",
            "Shift_End_Tm_Aggr": "20:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-02",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 8,
            "Clocked_Time_Hrs": 8.05,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-03",
            "Child_Cd": "Q2W10",
            "SCHED_YEAR_WEEK_NR": 202423,
            "Day_Name": "SUNDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "11:30",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "Early Out",
            "Approver_Name": "",
            "Excusal_Status_Cd": "Unexcused",
            "Week_Start_Dt": "2024-03-02",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 2.98,
            "Tot_Planned_Hours_Val": 8,
            "Clocked_Time_Hrs": 6.1,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-08",
            "Child_Cd": "Q2W10",
            "SCHED_YEAR_WEEK_NR": 202423,
            "Day_Name": "FRIDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-02",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.02,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-10",
            "Child_Cd": "Q2W11",
            "SCHED_YEAR_WEEK_NR": 202424,
            "Day_Name": "SUNDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "14:00",
            "Shift_End_Tm_Aggr": "18:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-09",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.07,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-11",
            "Child_Cd": "Q2W11",
            "SCHED_YEAR_WEEK_NR": 202424,
            "Day_Name": "MONDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:30",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-09",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.07,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-12",
            "Child_Cd": "Q2W11",
            "SCHED_YEAR_WEEK_NR": 202424,
            "Day_Name": "TUESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:30",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-09",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.07,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-13",
            "Child_Cd": "Q2W11",
            "SCHED_YEAR_WEEK_NR": 202424,
            "Day_Name": "WEDNESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:30",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-09",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.1,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-14",
            "Child_Cd": "Q2W11",
            "SCHED_YEAR_WEEK_NR": 202424,
            "Day_Name": "THURSDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:30",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-09",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.1,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-17",
            "Child_Cd": "Q2W12",
            "SCHED_YEAR_WEEK_NR": 202425,
            "Day_Name": "SUNDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "13:00",
            "Shift_End_Tm_Aggr": "17:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-16",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.07,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-18",
            "Child_Cd": "Q2W12",
            "SCHED_YEAR_WEEK_NR": 202425,
            "Day_Name": "MONDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:30",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-16",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.1,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-19",
            "Child_Cd": "Q2W12",
            "SCHED_YEAR_WEEK_NR": 202425,
            "Day_Name": "TUESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:30",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-16",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.15,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-20",
            "Child_Cd": "Q2W12",
            "SCHED_YEAR_WEEK_NR": 202425,
            "Day_Name": "WEDNESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:30",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-16",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.05,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-21",
            "Child_Cd": "Q2W12",
            "SCHED_YEAR_WEEK_NR": 202425,
            "Day_Name": "THURSDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:30",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-16",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.03,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-24",
            "Child_Cd": "Q2W13",
            "SCHED_YEAR_WEEK_NR": 202426,
            "Day_Name": "SUNDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "13:00",
            "Shift_End_Tm_Aggr": "17:00",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-23",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.1,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-25",
            "Child_Cd": "Q2W13",
            "SCHED_YEAR_WEEK_NR": 202426,
            "Day_Name": "MONDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:30",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-23",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.08,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-26",
            "Child_Cd": "Q2W13",
            "SCHED_YEAR_WEEK_NR": 202426,
            "Day_Name": "TUESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:30",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-23",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.1,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-27",
            "Child_Cd": "Q2W13",
            "SCHED_YEAR_WEEK_NR": 202426,
            "Day_Name": "WEDNESDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:30",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-23",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.03,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        },
        {
            "Emp_Name": "Abdelkarim, Karim",
            "Rptg_Dt": "2024-03-28",
            "Child_Cd": "Q2W13",
            "SCHED_YEAR_WEEK_NR": 202426,
            "Day_Name": "THURSDAY",
            "Week_Start_Day": "SAT",
            "Shift_Start_Tm_Aggr": "17:00",
            "Shift_End_Tm_Aggr": "21:30",
            "Event_Name": "No Event",
            "Approver_Name": "",
            "Excusal_Status_Cd": "No Event",
            "Week_Start_Dt": "2024-03-23",
            "Threshold_Val": 3,
            "Point_Balance_Val": 3,
            "Above_Threshold_Ind": "N",
            "Exclude_Occurrence_Ind": "N",
            "Tot_Impacted_Hours_Val": 0,
            "Tot_Planned_Hours_Val": 4,
            "Clocked_Time_Hrs": 4.13,
            "Excused_Benefit_Time_Hrs": 0,
            "Unexcused_Benefit_Time_Hrs": 0,
            "Unapproved_Abs_Hrs": 0,
            "Leave_Of_Absence_Hrs": 0,
            "Excused_Leader_Hrs": 0
        }
    ]; 
    const getStartDate = (dataObj) => {
        let firstObjVal = Object.values(dataObj)[0],
            obj = firstObjVal ?. length > 0 ? firstObjVal[0]: "",
            startDate = obj ? obj.Week_Start_Dt : "",
            formattedDate = startDate ? Util.getFormattedDate(startDate) : ""

        return formattedDate;
    }
    const groupByTwoProperties = (arr, key1, key2) => {
        return arr.reduce((result, obj) => {
          // Get the values of the two keys
          const key1Value = obj[key1],
            key2Value = obj[key2];
          
          // Initialize the first level if it doesn't exist
          if (!result[key1Value]) {
            result[key1Value] = {};
          }
          
          // Initialize the second level if it doesn't exist
          if (!result[key1Value][key2Value]) {
            result[key1Value][key2Value] = [];
          }
          
          // Add the object to the corresponding nested array
          result[key1Value][key2Value].push(obj);
          
          return result;
        }, {});
    }   

    const calendarGroupedData = groupByTwoProperties(calenderDetails, 'Child_Cd', 'Day_Name');
    const getLegendShapes = () => {
        let legendItemsDiv = [];

        for(let obj of Object.values(legendShapesTextMapping)) {
            let div =  <div class="calender-legend-item">
                <div class={obj.shapeCls}></div>
                <div class={`legend-shape-text ${obj.textCls}`}>{obj.text}</div>
            </div>

            legendItemsDiv.push(div);
        }

        return (<div className="legend-shapes">{legendItemsDiv}</div>)
    }
    const getLegendShortcuts = () => {
        let legendShortcutArr = [];

        for(let item in eventShortNameMapping) {
            let div = <div class="calender-legend-item">
                <div class="legend-shortcut-text">{eventShortNameMapping[item]}</div>
                <div class="legend-shortcut-text">{item}</div>
            </div>

            legendShortcutArr.push(div);
        }

        return (<div className="legend-shortcuts">{legendShortcutArr}</div>)
    }
    const getNoEventTooltipContent = (eventName, eventStatus, startDate, clockedTime) => {
        let formattedDate = Util.getFormattedDate(startDate),
            formattedClocktime = Util.getFormattedTime(clockedTime,':');

        let content = <div class="event-tooltip-div">
            <div class="event-tooltip-header">{eventName}</div>
            <div class="event-tooltip-date">
                <span class="event-tooltip-calendar-icon"></span>
                <span>{formattedDate}</span>
            </div>
            <div class="event-tooltip-clocktime">
                <span class="event-tooltip-clock-icon"></span>
                <span>{formattedClocktime}</span>
            </div>
        </div>
           
        return content;
    }
    const getUnexcusedDetails = (employeeDataObj) => {
        let unexcusedString = '';

        //if (employeeDataObj.Excusal_Status_Cd == 'Unexcused') {
            if (employeeDataObj.Unapproved_Abs_Hrs > 0) {
                unexcusedString = Util.getFormattedTime(employeeDataObj.Unapproved_Abs_Hrs,' ') + ' - ' + 'Unexcused' + ' - Leave'
            } else if (employeeDataObj.Unexcused_Benefit_Time_Hrs > 0) {
                unexcusedString = Util.getFormattedTime(employeeDataObj.Unexcused_Benefit_Time_Hrs,' ') + ' - ' + 'Unexcused' + ' - Time Away'
            }

            return unexcusedString
        //}
    }
    const getexcusedDetails = (employeeDataObj) => {
        let excusedString = '';

        //if (employeeDataObj.Excusal_Status_Cd == 'Excused') {
            if (employeeDataObj.Leave_Of_Absence_Hrs > 0) {
                excusedString = Util.getFormattedTime(employeeDataObj.Leave_Of_Absence_Hrs,' ') + ' - ' + 'Excused' + ' - Leave'
            } else if (employeeDataObj.Excused_Benefit_Time_Hrs > 0) {
                excusedString = Util.getFormattedTime(employeeDataObj.Excused_Benefit_Time_Hrs,' ') + ' - ' + 'Excused' + ' - Time Away'
            } else if (employeeDataObj.Excused_Leader_Hrs > 0) {
                excusedString = Util.getFormattedTime(employeeDataObj.Excused_Leader_Hrs,' ') + ' - ' + 'Excused' + ' - By Leader'
            }

            return excusedString
        //}
    }
    const getExcusedUnexcusedDetails = (employeeDataObj) => {
        let divArr = [],
            excusalDetails = getexcusedDetails(employeeDataObj),
            unExcusalDetails = getUnexcusedDetails(employeeDataObj);

        // if(employeeDataObj.Excusal_Status_Cd == 'Unexcused') {
        //     unExcusalDetails = getUnexcusedDetails(employeeDataObj);
        // } else if(employeeDataObj.Excusal_Status_Cd == 'Excused') {
        //     excusalDetails = getexcusedDetails(employeeDataObj);
        // }

        if(unExcusalDetails || excusalDetails) {
            divArr.push(<div className='separator'/>);

            if(unExcusalDetails) {
                divArr.push(<div class="event-tooltip-clocktime">
                    <span className="event-padding">{unExcusalDetails}</span>
                </div>)
            }
            if(excusalDetails) {
                divArr.push(<div class="event-tooltip-clocktime">
                    <span className="event-padding">{excusalDetails}</span>
                </div>)
            }
        }

        return <>{divArr}</>
    }
    const getEventTooltipContent = (employeeDataObj) => {
        let clockedTime = (employeeDataObj.Event_Name === 'No Event') ? employeeDataObj.Clocked_Time_Hrs : employeeDataObj.Tot_Impacted_Hours_Val,
            formattedDate = Util.getFormattedDate(employeeDataObj.Rptg_Dt),
            shiftTime = Util.getFormattedTime(employeeDataObj.Tot_Planned_Hours_Val, ' '),
            formattedClocktime = Util.getFormattedTime(clockedTime, ':');

        let content = <div class="event-tooltip-div">
            <div class="event-tooltip-header">{employeeDataObj.Event_Name} - {employeeDataObj.Excusal_Status_Cd}</div>
            <div class="event-tooltip-date">
                <span class="event-tooltip-calendar-icon"></span>
                <span>{formattedDate}</span>
            </div>
            <div class="event-tooltip-clocktime">
                <span class="event-tooltip-shift-icon"></span>
                <span>{shiftTime}{' Shift'}</span>
            </div>
            <div class="event-tooltip-clocktime">
                <span class="event-tooltip-impact-icon"></span>
                <span>{formattedClocktime}{' Impact'}</span>
            </div>
            {getExcusedUnexcusedDetails(employeeDataObj)}
        </div>

        return content;
    }

    const getColumnHeader = () => {
        let 
        schedulingWeek,
        headerArr = [];

    if(calenderDetails ?. length > 0) {
        let dataObj = calenderDetails[0];
        schedulingWeek = dataObj.Week_Start_Day ? dataObj.Week_Start_Day : 'SAT';
    }

    if(schedulingWeek) {
        for(let week of scheduleWeekMapping[schedulingWeek]) {
            headerArr.push(<th>{week}</th>)
        }
    }

    return (<tr>
        <th></th>
        {headerArr}
    </tr>)
    }
    const getSchedulingDayArr = () => {
        let  schedulingWeek;

        if(calenderDetails ?. length > 0) {
            let dataObj = calenderDetails[0];
            schedulingWeek = dataObj.Week_Start_Day ? dataObj.Week_Start_Day : 'SAT';
        }

        return scheduleWeekMapping[schedulingWeek];
    }
    const getTableBody = () => {
        let tableBody = [];

        for(let week in calendarGroupedData) {
            let calendarGroupedDataObj = calendarGroupedData[week],
                firstColumn = <td className='qtr-week-td'>
                    <div className='week-div'>{week}</div>
                    <div className='date-div'>{getStartDate(calendarGroupedDataObj)}</div>
                </td>,
                calendarItemsTd = [];
            let schedulingDayArr = getSchedulingDayArr();

            for(let schedulingDay of schedulingDayArr) {
                let schedulingDayCapital = dayCapitalMapping[schedulingDay],
                    employeeDataArr = calendarGroupedDataObj[schedulingDayCapital] ? calendarGroupedDataObj[schedulingDayCapital] : [],
                    employeeDataDiv = [];

                for(let employeeDataObj of employeeDataArr) {
                    let eventName = employeeDataObj.Event_Name,
                        eventStatus = employeeDataObj.Excusal_Status_Cd,
                        startDate = employeeDataObj.Rptg_Dt,
                        shift = employeeDataObj.Tot_Planned_Hours_Val,
                        clockedTime = (eventName === 'No Event') ? employeeDataObj.Clocked_Time_Hrs : employeeDataObj.Tot_Impacted_Hours_Val,
                        tooltipContent = (eventName === 'No Event') ? getNoEventTooltipContent(eventName, eventStatus, startDate, clockedTime) : getEventTooltipContent(employeeDataObj),
                        tooltipInd = true;

                    if(!eventName ) {
                        tooltipInd = false;
                    }

                    employeeDataDiv.push(
                        <div class="calendar-item">
                            {tooltipInd 
                                ?
                                    <BricksTooltip className="generic-tooltip" placement="top">
                                        <div slot="trigger" className={`info-tooltip calendar-item-shape ${legendShapesTextMapping[eventStatus].shapeCls}`}></div>
                                        <div className="tooltip-contents" >{tooltipContent}</div>
                                    </BricksTooltip>
                                :
                                <div className={`calendar-item-shape ${legendShapesTextMapping[eventStatus].shapeCls}`}></div>
                            }
                            <div className={`calendar-item-text ${legendShapesTextMapping[eventStatus].textCls}`}>{eventShortNameMapping[eventName]}</div>
                        </div>
                    )
                }

                calendarItemsTd.push(
                    <td className='event-td'>
                        <div class="calendar-items">{employeeDataDiv}</div>
                    </td>
                )
            }

            tableBody.push(
                <tr>
                    {firstColumn}
                    {calendarItemsTd}
                </tr>
            )
        }

        return tableBody;
    }

    return (
        <div className="calender-container">
            <div className="calender-header">Calendar View</div>
            
                <><div class="calender-legend">
                    {getLegendShapes()}
                    {getLegendShortcuts()}
                </div>
                <div className='calender-table'>
                    <table class="attendance-table">
                        <thead>{getColumnHeader()}</thead>
                        <tbody>
                            {getTableBody()}
                        </tbody>
                    </table>
                </div></>
            
        </div>
    )
}

export default Calender;