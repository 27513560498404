import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './SummaryContainer.scss';

const SummaryCointainer = () => {

    const summary = useSelector((state) => state.summary.data);

    const[impactHour,setImpactHour] = useState(0),
    [plannedHour,setPlannedHour] = useState(0),
    [hourPercentage,setHourPercentage] = useState(0),
    [shiftCnt,setShiftCnt] = useState(0),
    [plannedCnt,setPlannedCnt] = useState(0),
    [shiftPercentage,setShiftPercentage] = useState(0);
    
    const dispatch = useDispatch();

    useEffect(()=>{

        setImpactHour((summary.length > 0)? summary[0].Impacted_Hours_Val :0);
        setPlannedHour((summary.length > 0)? summary[0].Planned_Hours_Val : 0);
        setHourPercentage((summary.length > 0)? summary[0].Planned_Hrs_Impacted_PCT :0);
        setShiftCnt((summary.length > 0)? summary[0].Impacted_Shift_Cnt :0);
        setPlannedCnt((summary.length > 0)? summary[0].Planned_Shift_Cnt :0);
        setShiftPercentage((summary.length > 0)? summary[0].Planned_Shift_Impacted_PCT :0);
    },[summary])

    return (
        <>
            <div className='summary'>
                <div className="summary-label">Summary</div>
            </div>
            <div className='summary-card-container'>
            <div className="card card-hour">
                    <div className="card-header">Hours</div>
                    <div className="card-content row">
                        <div className="card-col">
                            <div className='card-col-header'>Impacted<br /> Hours </div>
                            <span >{impactHour}</span>

                        </div>
                        <div className="card-col">
                            <div className='card-col-header'>Planned<br /> Hours </div>
                            <span >{plannedHour}</span>{" "}

                        </div>
                        <div className="card-col">
                            <div className='card-col-header'>% Impact to Planned Hours </div>
                            <span>{(plannedHour != 0) ? ((impactHour / plannedHour) * 100).toFixed(0) : 0}{'%'}</span>

                        </div>
                    </div>
                </div>
                <div className="card card-hour">
                    <div className="card-header">Shifts</div>
                    <div className="card-content row">
                        <div className="card-col">
                            <div className='card-col-header'>Impacted<br /> Shifts </div>
                            <span >{shiftCnt}</span>

                        </div>
                        <div className="card-col">
                            <div className='card-col-header'>Planned<br /> Shifts </div>
                            <span >{plannedCnt}</span>{" "}

                        </div>
                        <div className="card-col">
                            <div className='card-col-header'>% Impact to Planned Shifts </div>
                            <span>{(plannedCnt != 0) ? ((shiftCnt / plannedCnt) * 100).toFixed(0) : 0}{'%'}</span>

                        </div>
                    </div>
                </div>
                <div className="card card-hour">
                    <div className="card-header">Impact by Excusal Type</div>
                    <div className="card-content row">
                        <div class="impact-section">
                            <div className="card-col-header">% Impact to Planned Hours</div>
                            <div class="impact-values">
                                <div class="impact-value">
                                    <p class="percentage">2%</p>
                                    <p class="label">Unexcused</p>
                                </div>
                                <div class="impact-value">
                                    <p class="percentage">3%</p>
                                    <p class="label">Excused</p>
                                </div>
                            </div>
                        </div>
                        <div class="impact-section">
                        <div className="card-col-header">% Impact to Planned Shifts</div>
                            <div class="impact-values">
                                <div class="impact-value">
                                    <p class="percentage">2%</p>
                                    <p class="label">Unexcused</p>
                                </div>
                                <div class="impact-value">
                                    <p class="percentage">3%</p>
                                    <p class="label">Excused</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-occurance">
                    <div className="card-header">Occurrences</div>
                    <div className="card-content">
                        <div className="card-col">
                            <div className='card-col-header'>Balance as of Today</div>
                            <span >{impactHour}</span>

                        </div>
                    </div>
                </div>
            
            </div>
        </>


    )
}

export default SummaryCointainer;