import React, { useState, useEffect } from 'react';
import './Header.scss';
import { BricksButton, BricksRadioButtonGroup, BricksRadioButton } from '@nexus/bricks-react';
import { useSelector, useDispatch } from 'react-redux';
import { setPopupState, setTimePeriodState, setAbsenceTypeState, setApplyClick } from '../../slices/headerSlice';
import { fetchData, fetchTimeframeData } from '../../slices/headerSlice';
import { fetchSummary } from '../../slices/summarySlice';
import * as XLSX from 'xlsx';


const Header = () => {
    const [tPeriod, setTPeriod] = useState(90);
    const [absenceType, setAbsenceType] = useState('All');
    const header = useSelector((state) => state.header);

    const time_periods = ['30', '60', '90', '180', 'Custom'];
    const absence_type = ['All', 'Unexcused Only', 'Excused Only'];
    const dispatch = useDispatch();

    
    useEffect(() => {   

        dispatch(fetchTimeframeData());

    }, []);

    useEffect(() => {
        // Path to the local Excel file in the public folder
        const filePath = `${process.env.PUBLIC_URL}/multiLingual/Personal_Absence_Thai.xlsx`;
    
        fetch(filePath)
          .then((response) => response.arrayBuffer()) // Get the file as an ArrayBuffer
          .then((data) => {
            const workbook = XLSX.read(data, { type: "array" });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
    
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            const keyValuePairs = jsonData.slice(1).reduce((acc, [key, , source]) => {
                acc[key] = source;
                return acc;
              }, {});
          })
          .catch((error) => {
            console.error("Error fetching the file:", error);
          });
      }, []);

    useEffect(() => {
        let timeframeData = header.timeframeData;
        if (timeframeData.length > 0) {
            let dateObj = timeframeData.find(entry => entry.Timeframe_Cd == 'CUSTOM_DAY_' + header.timePeriod);

            let params = {
                "StartDate": dateObj.MinDate,
                "EndDate": dateObj.MaxDate,
                "AbsenceType": header.absenceType
            }
            dispatch(fetchData(params));
            dispatch(fetchSummary(params));

        }


    }, [header.timeframeData])

    const handleSubmit = () => {
        dispatch(setTimePeriodState(tPeriod))
        let timeframeData = header.timeframeData;

        let dateObj = timeframeData.find(entry => entry.Timeframe_Cd == 'CUSTOM_DAY_' + tPeriod);
        if (tPeriod != 'Custom') {
            let params = {
                "StartDate": dateObj.MinDate,
                "EndDate": dateObj.MaxDate,
                "AbsenceType": header.absenceType
            }


            dispatch(fetchData(params));
            dispatch(fetchSummary(params));

        }
        dispatch(setPopupState(false))
        dispatch(setApplyClick(true))

    }
    const handleChange = e => {
        const element = e.currentTarget
        setTPeriod(element.value)
    };
    const getFilterPopUp = () => {
        let filterpopup = <div className="filter-info-popup" style={{ display: (header.isPopupClicked == true) ? "block" : "none" }} >
            <div id="FilterContainer">
                <div id="inner-FilterContainer">
                    <div className="filterbody">
                        <div className="time-period">
                            <BricksRadioButtonGroup className="radio-button-group" label="Time Period" name="Time" onBricksRadioButtonGroupBlur={handleChange}>
                                {time_periods.map((time) => (
                                    <BricksRadioButton
                                        key={time}
                                        label={time + ((time !== 'Custom') ? ' Days' : '')}
                                        value={time}
                                        checked={(header.timePeriod == time) ? true : false}

                                    />
                                ))}

                            </BricksRadioButtonGroup>
                        </div>
                        <div className="Absence">
                            <BricksRadioButtonGroup className="radio-button-group" label="Absence Type" name="Absence">
                                {absence_type.map((type) => (
                                    <BricksRadioButton
                                        key={type}
                                        label={type}
                                        value={type}
                                        checked={(header.absenceType == type) ? true : false}
                                        onClick={() => setAbsenceTypeState(type)} // Need to dispatch this or else it will not work
                                    />
                                ))}

                            </BricksRadioButtonGroup>
                        </div>
                    </div>
                    <div className="filter-botton-container">
                        <BricksButton visualStyle="alternative" accessibleTitle="Alternative" className="cancel" onClick={() => dispatch(setPopupState(false))}>Cancel</BricksButton>
                        <BricksButton visualStyle="primary" accessibleTitle="primary" className="submit" onClick={handleSubmit}>Apply</BricksButton>
                    </div>
                </div>
            </div>
        </div>

        return filterpopup;
    }

    const onPopupClick = () => {
        dispatch(setPopupState(!header.isPopupClicked));
    }
    return (
        <div id="header">
            <div className='header-label'>
                Personal Absence Report
            </div>
            <div className='vertical-center'>
                <div className='timeframe'>
                    {/* <div className='period'>
                        <div>Time Period</div>
                        <div>{(header.timePeriod == 'Custom') ? '' : 'Last'} {header.timePeriod} {(header.timePeriod == 'Custom') ? '' : 'Days'}</div>
                    </div> */}
                    <div className='period'>
                        <div className="timeperiod">Time Period</div>
                        <div className="timeperiod-value">{(header.timePeriod == 'Custom') ? '' : 'Last'} {header.timePeriod} {(header.timePeriod == 'Custom') ? '' : 'Days'}</div>
                    </div>
                    <div className='separator-div'></div>
                    <div className='period'>
                        <div className="timeperiod">Absence Type</div>
                        <div className="absencetype-value">All</div>
                    </div>
                </div>
                <div className={(header.isPopupClicked) ? 'expand-collapse-collapse' : 'expand-collapse-expand'} onClick={onPopupClick}></div>
            </div>
            <div className='header-icon'></div>
            {getFilterPopUp()}
        </div>
    )

}

export default Header;