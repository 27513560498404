import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import loadmask from '../component/loadmask/loadmask';

const initialState = {
  isPopupClicked: false,
  isApplyClicked: false,
  data:[],
  timeframeData:[],
  error:null,
  tab:'Hours Impacted',
  timePeriod:'90',
  absenceType:'All',
  loading:false,
}

export const fetchTimeframeData = createAsyncThunk(
  'data/fetchTimeframeData', // a string identifier for the action type
  async () => {
    loadmask.show();
    try {
      let headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        "Accept": "application/json",
        "Cache": "no-cache",
        "X-Requested-With": "XMLHttpRequest",
        "CSRF": window.csrf_token_val
    };
    
      // Perform asynchronous operation here, such as fetching data from an API
      const timeframeResponse = await axios({
        url: '/gsf/amteamview/businessareas/retail/subjectareas/amteamview/services/get_absence_reporting_date/',
        headers: headers,
        method: 'POST'})// Return the data fetched from the server
        loadmask.hide();

        
      return timeframeResponse.data;
      

    } catch (error) {
      loadmask.hide();
      throw error;
    }
  }
);
export const fetchData = createAsyncThunk(
  'data/fetchData', // a string identifier for the action type
  async (data) => {

    
    loadmask.show();
    try {
      let headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        "Accept": "application/json",
        "Cache": "no-cache",
        "X-Requested-With": "XMLHttpRequest",
        "CSRF": window.csrf_token_val
      };

      let postData = {
        filters: {
          StartDate: [data.StartDate],
          EndDate: [data.EndDate],
          ABSENCE_TYPE_CD: [data.AbsenceType],
        }
      };
    
      // Perform asynchronous operation here, such as fetching data from an API
      const response = await axios({
        url: '/gsf/amteamview/businessareas/retail/subjectareas/amteamview/services/lor_absence_team_view/',
        headers: headers,
        data:postData,
        method: 'POST'})// Return the data fetched from the server
        loadmask.hide();
       
      return response.data;
      

    } catch (error) {
      loadmask.hide();
      throw error;
    }
  }
);


export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setPopupState: (state, action) => {
      state.isPopupClicked = action.payload
    },
    setApplyClick: (state, action) => {
      state.isApplyClicked = action.payload
    },
    setTabstate:(state,action)=>{
      state.tab = action.payload
    },
    setTimePeriodState:(state,action)=>{
      state.timePeriod = action.payload
    },
    setAbsenceTypeState:(state,action)=>{
      state.absenceType = action.payload
    }
  
  },
  extraReducers: (builder) => {
    /**
     * fetchData logic here.
     */
    builder.addCase(fetchData.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.data = action.payload.result
      state.loading = false
      state.isPopupClicked = false
    })

    builder.addCase(fetchTimeframeData.fulfilled, (state, action) => {
      state.timeframeData = action.payload.result
      state.isPopupClicked = false
    })

    builder.addCase(fetchData.rejected, (state, action) => {
      const { error } = action
      state.error = error
      state.loading = false
    })
},
})

export const { setPopupState,setApplyClick,setTabstate,setTimePeriodState,setAbsenceTypeState } = headerSlice.actions

export default headerSlice.reducer