import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import loadmask from '../component/loadmask/loadmask';

const initialState = {
  data:[],
  error:null,
  loading:false,
}
export const fetchSummary = createAsyncThunk(
  'data/fetchSummary', // a string identifier for the action type
  async (data) => {
    loadmask.show();
    try {
      let headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        "Accept": "application/json",
        "Cache": "no-cache",
        "X-Requested-With": "XMLHttpRequest",
        "CSRF": window.csrf_token_val
    };
    let postData = {
      filters: {
        StartDate: [data.StartDate],
        EndDate: [data.EndDate],
        ABSENCE_TYPE_CD: [data.AbsenceType],
      }
    };
    
      // Perform asynchronous operation here, such as fetching data from an API
      const response = await axios({
        url: '/gsf/amteamview/businessareas/retail/subjectareas/amteamview/services/lor_absence_team_view_summary/',
        headers: headers,
        data:postData,
        method: 'POST'})// Return the data fetched from the server
        loadmask.hide();
      return response.data;
      

    } catch (error) {
      loadmask.hide();
      // If an error occurs, reject the promise with the error object
      throw error;
    }
  }
);


export const sumarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    /**
     * fetchData logic here.
     */
    builder.addCase(fetchSummary.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchSummary.fulfilled, (state, action) => {
      state.data = action.payload.result
      state.loading = false
    })

    builder.addCase(fetchSummary.rejected, (state, action) => {
      const { error } = action
      state.error = error
      state.loading = false
    })
},
})

//export const { setPopupState,setTabstate } = headerSlice.actions

export default sumarySlice.reducer