import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import RootRouter from "./routes/RootRouter";

const App = ({reporter}) => {
  return (
    <BrowserRouter>
      <RootRouter reporter={reporter} />
    </BrowserRouter>
  );
};

export default App;
