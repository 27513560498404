
import "./loadmask.scss";

export default {
		count:0,
		show: function(pref) {
			this.count =this.count+1;
			document.getElementById("blue-dot-loadmask").style.display="block";
	  	},
		hide: function(force){
			this.count = this.count ===0?0: this.count-1;
			if(this.count === 0 || force)
			document.getElementById("blue-dot-loadmask").style.display="none";
		}
};